<template>
  <modal class="checkOutRecoveryModel" :isShow="isShow" @keyup.esc="hide">
    <div class="header">{{isRedBill==true?'红冲':'结账恢复'}}</div>
    <div class="modal-body">
        <div class="title">您确认将营业日期 {{data.Rpt_Date}}的{{data.EAT_ROOMNAME}}，帐单号为：{{data.Eat_CheckIndex}}{{isRedBill==true?'红冲':'结账恢复'}}吗？</div>
        <div class="content-box">
            <div class="lable-text">请选择原因</div>
            <div class="reason-box" v-mouse-scroll>
                <div class="reason-tag nowrap" :class="{selected:reasonInfo?.RefundReason_AutoID==item.RefundReason_AutoID}" v-for="item in (reasonList||[])" :key="item" @click="selectReason(item)">{{item.RefundReason_Name}}</div>
            </div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <template v-if="isRedBill" >
            <button class="btn btn-confirm" @click="confirm(true)">红冲并打印结账单</button>
            <button class="btn btn-confirm" @click="confirm(false)">确认</button>
        </template>
        <button v-else class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
  </modal>
</template>

<script>
export default {
    name:'checkOutRecoveryModel',
    props:{
        isShow:Boolean,
        isRedBill:Boolean,
        /**需要展现 的数据 */
        data:{
            /**餐厅名称 */
            EAT_ROOMNAME:String,
            /** 营业日期 */
            Rpt_Date:String,
            /** 帐单号*/
            Eat_CheckIndex:String
        }
    },
    data(){
        return{
            /**退菜原因数据 */
            reasonList:[],
            /**选中的原因 */
            reasonInfo:undefined,
        }
    },
    watch:{
        isShow(value){
            if(value==true){//显示弹层时 初始化数据
                this.reasonInfo=undefined;
                if(!this.reasonList || this.reasonList.length==0){
                    this.loadData();
                }
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.loadData();
        })
    },
    methods:{
         /**加载 原因 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.RefundReasons().then((d)=>{
                loading.close();
                if(d){
                    this.reasonList=d||[];
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载原因数据失败：'+e,);
                console.log('加载原因数据失败：'+e);
            })
        },
        selectReason(item){
            this.reasonInfo=item;
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(print){
            if(this.reasonInfo){
                this.$confirm('您确定'+(this.isRedBill==true?'红冲':'结账恢复')+'吗？', "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            let data=Object.assign({},this.reasonInfo);
                            if(this.isRedBill){//红冲
                                data.Print_YN=print;//是否打印结账单
                            }
                            this.$emit("confirm",data);
                        }
                    },
                });
               
            }else{
                 this.$message.warning('请先选择'+(this.isRedBill==true?'红冲':'结账恢复')+'原因!');
            }
        }
    }
}
</script>

<style lang="scss">
   @import "./checkOutRecoveryModel.scss"
</style>